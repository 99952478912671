<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEarningTable"
        class="position-relative"
        selectable
        :select-mode="selectMode"
        :items="earnings"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
         @row-selected="onRowSelected"
      >


      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>

        <!-- Column: Company Share -->
        <template #cell(companyShare)="data">
           <div class="text-nowrap">
            <strong>
              {{(data.item.totalPrice - data.item.riderEarnedAmount)}}
              </strong>
           </div>
        </template>  
       
       
        <!-- Column: Commission -->
        <!-- <template #cell(commissionPercentage)="data">
           <div class="text-nowrap">
            <strong>
              {{(data.item.totalPrice - data.item.riderEarnedAmount)}}
              </strong>
           </div>
        </template>   -->
       
        <!-- Column: Date -->
        <template #cell(createdAt)="data">
           <div class="text-nowrap">
            <strong>
              {{formatDate(data.item.createdAt)}}
              </strong>
           </div>
        </template>  
       
        <!-- Column: Status -->
        <template #cell(status)="data">
           <div v-if="data.item.status" class="text-nowrap">
             <b-badge variant="light-success">
          {{data.item.status.toUpperCase()}}
        </b-badge>
            <!-- <strong>
              {{data.item.status.toUpperCase()}}
              </strong> -->
           </div>
        </template>  
      
        <!-- Column: Status -->
        <template #cell(type)="data">
           <div v-if="data.item.type" class="text-nowrap">
            <strong>
              {{data.item.type.toUpperCase()}}
              </strong>
           </div>
        </template>  

       <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button v-if="!data.item.status" @click="updateEarningStatus(data.item.token)">
              <feather-icon icon="SquareIcon" />
              <span> Update Status</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Selection toggles -->
        <div class="demo-inline-spacin d-flex align-items-right">
       <b-button
      size="sm"
      class="ml-2"
      variant="primary"
      @click="selectAllRows"
    >
      Select all
    </b-button>
    <b-button
      size="sm"
      class="ml-1"
      variant="primary"
      @click="clearSelected"
    >
      Clear selected
    </b-button>
    <b-button
    v-if="selected.length"
      size="sm"
      class="ml-1"
      variant="primary"
      @click="updateStatuses(selected)"
    >
      Update selected
    </b-button>
    </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEarnings"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BBadge
} from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import useEarning from "./useEarning";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectMode: 'range',
      selected: [],
    };
  },
  methods: {
     onRowSelected(items) {
      this.selected = items.map(s => s.token)
      // console.log(this.selected)
    },
    selectAllRows() {
      this.$refs.refEarningTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refEarningTable.clearSelected()
    },
  },
  setup() {
    onMounted(() => {
      fetchEarnings();
    });

    const updateStatuses = (selected) => {
      updateEarningsStatus(selected)
    }

    const {
      fetchEarnings,
      updateEarningStatus,
      updateEarningsStatus,
      earnings,

      tableColumns,
      perPage,
      currentPage,
      totalEarnings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEarningTable,
      refetchData,
    } = useEarning();

    return {

      fetchEarnings,
      refetchData,
      updateEarningStatus,
      // updateEarningsStatus,
      updateStatuses,
      formatDate,

      earnings,
      tableColumns,
      perPage,
      currentPage,
      totalEarnings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEarningTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
