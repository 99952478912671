import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { formatDate, title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const earnings = ref([])
const filteredEarnings = ref([])

export default function useEarning() {
  // Use toast
  const toast = useToast()

  const refEarningTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'type', sortable: true },
    { key: 'totalPrice', sortable: true },
    { key: 'riderEarnedAmount', label: 'Rider\'s Share'},
    { key: 'companyShare', label: 'Company\'s Share'},
    { key: 'commissionPercentage', label: 'Commission'},
    { key: 'createdAt', label: 'Date' },
    { key: 'status', label: 'Paid In / Paid Out' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalEarnings = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refEarningTable.value ? refEarningTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEarnings.value,
    }
  })

  const refetchData = () => {
    refEarningTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = earnings.value.filter(
      earning => earning.type?.includes(queryLowered) || earning.status?.toLowerCase().includes(queryLowered) ||
                formatDate(earning.createdAt).toLowerCase().includes(queryLowered)
    )
    filteredEarnings.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalEarnings.value = filteredData.length
    refetchData()
  })

  
  function updateEarning(earning) {
    earnings.value = earnings.value.filter(x => x.id != earning.id);
    earnings.value.push(earning);
    earnings.value.sort(function (a, b) {
      return b.id - a.id;
    });

    filteredEarnings.value = paginateArray(earnings.value, perPage.value, currentPage.value)
  }

  function updateEarnings(earnings) {
    earnings.forEach(earning => {
      updateEarning(earning);
    });

  }

  const fetchEarnings = (ctx) => {
    if(earnings.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.EARNING)
      .then(({data}) => {
        earnings.value = data;
        filteredEarnings.value = paginateArray(earnings.value, perPage.value, currentPage.value)
        totalEarnings.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching earnings',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateEarningStatus = (payment) => {
    return useJwt.axiosIns.post(routes.UPDATE_EARNING, {payment})
    .then(({data}) => {
        updateEarning(data)
        toast({
          component: ToastificationContent,
          props: {
            title: "Eearning status updated",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        return data
    })
    .catch(({response}) => {
      console.log(response);
      toast({
          component: ToastificationContent,
          props: {
            title: "Error updating earning ",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
    });
}
  const updateEarningsStatus = (payments) => {
    return useJwt.axiosIns.post(routes.UPDATE_EARNINGS, {payments})
    .then(({data}) => {
        updateEarnings(data)
        toast({
          component: ToastificationContent,
          props: {
            title: "Eearnings status updated",
            icon: "CheckIcon",
            variant: "success",
          },
        });
    })
    .catch((error) => {
      console.log(error)
      toast({
          component: ToastificationContent,
          props: {
            title: "Error updating earning ",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
    });
}

  return {
    fetchEarnings,
    updateEarningStatus,
    updateEarningsStatus,
    tableColumns,
    perPage,
    currentPage,
    totalEarnings,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEarningTable,
    refetchData,
    earningCount: computed(() => earnings.value.map(e => e.totalPrice).reduce((p, c) => p+c,0)),
    earnings: computed(() => filteredEarnings.value)
  }
}
